export default {
    title: "Fees and Booking an Appointment",
    description: `
        <h4>Fees and Rebates:</h4></br>

        <h4><i>Clinical Psychologists</i></h4></br>

        <strong><h5><i>Dr Talia Hashworth</i></h5>

        <p>Initial Consultation (50 minutes): $270 (Out-of-pocket cost: $128.15)</p>
    
        <p>Standard Session (50 minutes): $255 (Out-of-pocket cost: $113.15)</p>
    
        <p>Extended EMDR Session (90 minutes): $459 (Out-of-pocket cost: $317.15)</p></strong>
    
        <p>A Medicare rebate of $141.85 may apply if you have a valid referral and Mental Health Treatment Plan from your doctor. You may be eligible to claim the Medicare rebate on up to 10 sessions per calendar year. You are not obliged to use all 10 sessions if you choose not to, but are able to if you and your treating team feel it is relevant.</p>

        <p>This means, for all standard 50 minute sessions ($255 per session), you may be able to get a rebate of $141.85 per session, leaving a gap payment of $113.15 per session.</p>

        <p>If you have private healthcare coverage, please contact your provider directly to see if you are entitled to any rebates.</p></br>

        <strong><h5><i>Olivia Green</i></h5>

        <p>Initial Consultation (50 minutes): $255 (Out-of-pocket cost: $113.15)</p>
    
        <p>Standard Session (50 minutes): $245 (Out-of-pocket cost: $103.15)</p>
    
        <p>Extended EMDR Session (90 minutes): $441 (Out-of-pocket cost: $299.15)</p></strong>
    
        <p>A Medicare rebate of $141.85 may apply if you have a valid referral and Mental Health Treatment Plan from your doctor. You may be eligible to claim the Medicare rebate on up to 10 sessions per calendar year. You are not obliged to use all 10 sessions if you choose not to, but are able to if you and your treating team feel it is relevant.</p>

        <p>This means, for all standard 50 minute sessions ($255 per session), you may be able to get a rebate of $137.05 per session, leaving a gap payment of $107.95 per session.</p>

        <p>If you have private healthcare coverage, please contact your provider directly to see if you are entitled to any rebates.</p></br>

        <h4><i>Registered Psychologists</i></h4></br>

        <strong><h5><i>Jasmin Cox, Sara Oldfield, and Luke Fullagar</i></h5>

        <p>Initial Consultation (50 minutes): $209 (Out-of-pocket cost: $112.35)</p>
    
        <p>Standard Session (50 minutes): $199 (Out-of-pocket cost: $102.35)</p>
    
        <p>Extended EMDR Session (90 minutes): $358.20 (Out-of-pocket cost: $261.55)</p></strong>
    
        <p>A Medicare rebate of $96.65 may apply if you have a valid referral and Mental Health Treatment Plan from your doctor. You may be eligible to claim the Medicare rebate on up to 10 sessions per calendar year. You are not obliged to use all 10 sessions if you choose not to, but are able to if you and your treating team feel it is relevant.</p>

        <p>This means, for all standard 50 minute sessions ($199 per session), you may be able to get a rebate of $96.65 per session, leaving a gap payment of $102.35 per session.</p>

        <p>If you have private healthcare coverage, please contact your provider directly to see if you are entitled to any rebates.</p></br>

        <h4><i>Accredited Mental Health Social Workers</i></h4></br>
        <strong><h5><i>Eric Rangel Oliveira</i></h5>
        <p>Initial Consultation (50 minutes): $195</p>
    
        <p>Standard Session (50 minutes): $185</p>
    
        <p>Extended EMDR Session (90 minutes): $333</p></strong>
        
        
        <h4><i>Accredited Social Workers, Counsellors, and Provisional Psychologists</i></h4></br>
    
        <strong><h5><i>Laura Corban, Rachel Mintoff, Sala Ghanem, Nicole Herrald, and Julia Messore</i></h5>

        <p>Initial Consultation (50 minutes): $85-135</p>
    
        <p>Standard Session (50 minutes): $85-135</p>
    
        <p>Extended EMDR Session (90 minutes): $153-243</p></strong>
        
        <p>Reduced fees may be applicable in the event of financial hardship. Please contact us to discuss this further.</p>

        <p>Unfortunately, Medicare does not pay a rebate for services provided by a provisional psychologist, counsellor, or accredited social worker. To minimise financial burden, our provisional psychologists, counsellors, and psychotherapists have reduced fees and offer evening and weekend sessions.</p>

        <p>If you have private healthcare coverage, please contact your provider directly to see if you are entitled to any rebates.</p></br>

        <h4><i>Therapy Assistants</i></h4></br>

        <strong><h5><i>Marcus Celebi</i></h5>

        <p>Initial Consultation (50 minutes): $85</p>
    
        <p>Standard Session (50 minutes): $85</p></strong>

        <p>To ensure accessibility to services, our therapy assistants offer very reduced rates while undergoing their internship with Hashworth Psychology's Diversity Clinic. Evening and weekend sessions are also available.</p>
        <p>Unfortunately, Medicare does not pay a rebate for services provided by a therapy assistant.</p>
        <p>If you have private healthcare coverage, please contact your provider directly to see if you are entitled to any rebates.</p></br>

        
        <strong><p>Booking an appointment at Hashworth Psychology is as easy as ever.</p></strong>
    `,
    img: "",
    section1: {
        heading: "New Clients",
        description: `
            <p>If you're a first-time client, please call us on <a href="tel:0491-683-910">0491 683 910</a> or email us at <a href="mailto:connect@hashworthpsychology.com.au">connect@hashworthpsychology.com.au</a> to discuss your current situation and your preferred appointment time(s). By servicing the larger Illawarra and Sydney regions, sessions can be held in Wollongong NSW or remotely via telehealth. If you have any specific questions or concerns, we’d be happy to support you in determining if Hashworth Psychology is the best fit for you.</p>
        `
    },
    section2: {
        heading: "Existing Clients",
        description: `
        If you are an existing client of Dr Talia Hashworth, please use the "Book an Appointment" link below and select your preferred appointment type and time. We will see you again, soon!
        `
    },
    button: {
        title: "Book an Appointment",
        description: `<p>This booking system is only available for Dr Hashworth's existing clients. Please contact our friendly admin team to book in with any of our other practitioners.</p> You'll be taken to a third-party website to complete your booking. If you have any issues, please email us at <a href="mailto:connect@hashworthpsychology.com.au">connect@hashworthpsychology.com.au</a></p>`
    },
    faqButton: {
        title: "Check out our FAQ page",
        description: "Didn't find what you were looking for?"
    }, 
}