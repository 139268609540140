import React from "react";
import DOMPurify from "dompurify";
import { withRouter } from "react-router-dom";
import Button from "react-bootstrap/Button";

import { scrollTo } from "../../helpers/scrollHelper";
import "./ContentImageBox.css";

class ContentImageBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.scrollDiv = React.createRef();
    }

    handleClick = () => {
        this.props.setActive(this.props.index);
    }

    render() {
        const description = { __html: DOMPurify.sanitize(this.props.subject.description) };
        const row = ~~((this.props.index - 1) / 3) + 1;
        const navigateTo = () => {
            this.props.history.push("/appointments");
            window.scrollTo(0, 0);
        };

        let renderInformationBox;
        if (this.props.active === this.props.index) {
            renderInformationBox = (
                <div className="contentInfoBox" ref={this.scrollDiv} style={{ gridRowStart: row + 1 }}>
                    <h4>{this.props.subject.title}</h4>
                    <div dangerouslySetInnerHTML={description}></div>
                    <p>
                        <i>If this sounds like something you are experiencing, reach out to Hashworth Psychology's Diversity Clinic using the button below to start your journey towards recovery.</i>
                    </p>
                    <Button className="clickableBtn" size="lg" variant="primary" onClick={navigateTo}>
                        Book an Appointment
                    </Button>
                </div>
            )

            scrollTo(this.scrollDiv);
        }

        return (
            <>
                <div className="contentImageBox">
                    <div className="contentTile" onClick={this.handleClick}>
                        {/* <img src={this.props.subject.img} className="contentImage"></img> */}
                        <img src="" className="contentImage"></img>
                        <div>
                            <h3 className="contentText">{this.props.subject.title}</h3>
                        </div>
                    </div>
                </div>
                {renderInformationBox}
            </>
        );
    }
}

export default withRouter(ContentImageBox);