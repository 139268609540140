import React from "react";
import { Helmet } from "react-helmet";
import DOMPurify from "dompurify";

import Custom from "./services-config";
import Footer from "../footer/Footer";
import Navbar from "../navbar/Navbar";
import ContactUs from "../contactUs/ContactUs";
import ContentImageBox from "../contentImageBox/ContentImageBox";

import "./Services.css";

class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: 0
        }
    }

    setActive = (key) => {
        if (this.state.active === key) {
            this.setState({active: 0});
        } else {
            this.setState({active: key});
        }
    }

    render() {
        return (
            <>
                <Helmet>
                    <title>Our Services</title>
                    <meta name="description" content="Hashworth Psychology's Diversity Clinic offers a variety of services tailored to your needs including individual psychology sessions, extended EMDR sessions and a Dialectical Behaviour Therapy group program for adolescents and adults." />
                </Helmet>

                <Navbar {...this.props} page="services"/>
                <div className="titleBgImg">
                    <div className="titleBanner">
                        <div className="titleContent">
                            <h1>{Custom.title}</h1>
                        </div>
                    </div>
                </div>
                <div className="servicesContent">
                    <div className="servicesIntro">
                        <h3>{Custom.subtitle}</h3>
                        <p>{Custom.description}</p>
                    </div>  
                    <div className="servicesGrid">
                        {Custom.services.map((service, i) => {
                            const index = i + 1;
                            return(<ContentImageBox key={i} index={index} setActive={this.setActive} active={this.state.active} subject={service}/>);
                        })}
                    </div>
                    <div className="servicesSessions">
                        <div className="servicesIntro">
                            <h3>{Custom.subheading}</h3>
                            <p>{Custom.sessionsDescription}</p>
                        </div>
                        <div className="sessionsContent">
                            {Custom.sections.map((section, i) => {
                                const sectionDescription = {__html: DOMPurify.sanitize(section.description)};

                                return(
                                    <div key={i} className="sessionsSection">
                                        <h4>{section.heading}</h4>
                                        <div dangerouslySetInnerHTML={sectionDescription}></div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <ContactUs/>
                <Footer/>
            </>
        );
    }
}

export default Services;