import taliaProfile from "../../img/staff/talia-profile.JPG";
import caitlinProfile from "../../img/staff/caitlin-profile.jpg";
import juliaProfile from "../../img/staff/julia-profile.png";
import alanaProfile from "../../img/staff/alana-profile.png";
import salaProfile from "../../img/staff/sala-profile.jpg";
import oliviaProfile from "../../img/staff/olivia-profile.jpg";
import marcusProfile from "../../img/staff/marcus-profile.jpg";
import nicoleProfile from "../../img/staff/nicole-profile.jpg";
import jasminProfile from "../../img/staff/jasmin-profile.jpg";
import lukeProfile from "../../img/staff/luke-profile.jpeg";
import rachelProfile from "../../img/staff/rachel-profile.jpg";
import KatProfile from "../../img/staff/kat-profile.jpg";
import athenaProfile from "../../img/staff/athena-profile.jpg";
import saraProfile from "../../img/staff/sara-profile.png";
import elizaProfile from "../../img/staff/eliza-profile.jpg";
import JordanProfile from "../../img/staff/jordan-profile.jpeg";
import lauraProfile from "../../img/staff/laura-profile.jpeg";
import darcyProfile from "../../img/staff/darcy-profile.jpeg";
import rozhinaProfile from "../../img/staff/rozhina-profile.png";
import ericProfile from "../../img/staff/eric-profile.jpg";

export default {
    title: "About Us",
    description: "We’re dedicated to create a space for your recovery and development. We believe that you are the expert of your own experiences and our role is to encourage you to see yourself as the brave, courageous, and tenacious individual that we see you as.",
    socialMediaText: `
        <p><i>Follow our <a href="https://www.facebook.com/hashworthpsychology">Facebook</a> and <a href="https://www.instagram.com/hashworthpsychology">Instagram</a> pages to keep up to date with Hashworth Psychology's Diversity Clinic's posts!</i></p>
    `,
    staffHeader: "Our team",
    clinicalStaff: {
        header: "Clinical",
        staff: [
            {
                img: taliaProfile,
                heading: "Dr Talia Hashworth",
                title: " Principal Clinical Psychologist/Director",
                pronouns: "She/Her",
                researchButton: true,
                description: `
                    <p>Dr Talia Hashworth is the founder of Hashworth Psychology Pty Ltd, a unique mental health service in the Wollongong CBD. Dr Hashworth completed both a Master of Clinical Psychology degree and a Doctor of Philosophy (Ph.D.) Clinical Psychology degree at the University of Wollongong. Dr Hashworth’s research focused on examining borderline personality disorder, locus of control (i.e., our sense of agency, autonomy, and perceived control over ourselves and our environment), attachment styles (i.e., how we interact in interpersonal situations), and traumatic experiences (particularly childhood trauma).</p>
                    <p>Originally from Canada, Dr Hashworth has extensive experience in a range of public and private mental health settings, such as private practice, community mental health, psychiatric hospitals (inpatient and outpatient), and crisis centres.</p>
                    <p>Dr Hashworth has ample experience implementing evidence-based psychological therapies and has a particular interest in working with adolescents and young adults. Dr Hashworth is an accredited EMDR Consultant, awarded to her by the EMDR Board of Australia (EMDRAA). Dr Hashworth’s primary areas of interest include working with trauma (PTSD and complex trauma), borderline personality disorder, and LGBTQIA+ health. Dr Hashworth uses a range of validated and evidence-based approaches, including, but not limited to:</p>
                    <ul>
                        <li>Eye Movement Desensitisation Reprocessing (EMDR) Therapy</li>
                        <li>Dialectical Behavioural Therapy (DBT)</li>
                        <li>Schema Therapy</li>
                        <li>Cognitive Behavioural Therapy (CBT)</li>
                        <li>Trauma-Focused CBT</li>
                        <li>Acceptance & Commitment Therapy (ACT)</li>
                        <li>Motivational Interviewing (MI)</li>
                        <li>Feedback Informed Treatment (FIT)</li>
                        <li>Exposure Therapy and Response Prevention (ERP)</li>
                    </ul>
                    <p>When working with Dr Hashworth, she focuses on supporting you to build your resilience, achieve your goals, develop a sense of control and security within yourself, increase your self-worth, self-understanding, and self-compassion, acquire skills and techniques to better manage difficult situations, and improve your overall wellbeing. Dr Hashworth works with you to ensure you feel safe and comfortable and tailors treatment to your individual needs. She welcomes individuals of all gender and sexual orientations, identities, and expressions, with a strong focus on empowering LGBTQIA+ individuals.</p>
                    <p>Dr Hashworth works with Medicare, NDIS (self managed), WorkCover, and private clients.</p>
                `
            },
            {
                img: ericProfile,
                heading: "Eric Rangel Oliveira",
                title: "Accredited Mental Health Social Worker",
                pronouns: "He/Him",
                researchButton: false,
                description: `
                    <p>Eric is an Accredited Mental Health Social Worker with the Australian Association of Social Workers (AASW), with four years of clinical experience in the Mental Health, Drug & Alcohol and Disability field. His professional journey includes working in both public and private sectors, supporting young people, adults, and families through community mental health services (clinical outpatient settings), early intervention programs, alcohol and other drug services, and within the NDIS sector.</p>

                    <p>Eric has experience assisting clients with challenges such as depression, stress, anxiety, psychosis, addiction problems, LGBTIQA+ minority stress-related issues, relationship difficulties, and difficulties around motivation, self-esteem, and self-confidence.</p>
                    
                    <p>He is passionate about working collaboratively with clients to explore how past experiences related to attachment styles, trauma and unmet needs interact to shape an individual’s coping styles and behaviours. Eric is particularly interested in supporting people experiencing issues such as anxiety, depression, obsessive-compulsive disorders, substance use problems, impulse control issues (e.g., hypersexuality, binge eating, shopping, gambling, etc), trauma and stress related issues and physical health problems (chronic pain, cardiovascular issues and diabetes). He aims to help build resilience, enhance self-efficacy, and achieve overall well-being through sustainable behavioural change.</p>
                    <p>Eric practices from an integrative approach to psychotherapy, drawing from several treatment models and psychotherapy modalities and approaches, such as Trauma-Informed Care, Harm Reduction, Motivational Interviewing, Cognitive Behavioural Therapies (CBT, ACT and DBT), Schema Therapy, Self-Compassion and Mindfulness-Based Interventions. He will also be undergoing training in Eye Movement desensitization and Reprocessing (EMDR).</p>
                    
                    <p>Eric can see clients under Medicare, NDIS (self and plan managed), WorkCover, and private clients. He is also in the process of becoming a Victims Services approved practitioner.</p>
                `
            },
            {
                img: jasminProfile,
                heading: "Jasmin Cox",
                title: "Psychologist",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Jasmin is a registered psychologist with AHPRA who is committed to walking alongside her clients in their journey towards positive mental health and wellbeing. With a breadth of experience, she has worked with adolescents, adults and families across various areas including anxiety, depression, addiction, parenting, relationship challenges, trauma, personality disorders as well as assessment and diagnosis. Jasmin also enjoys facilitating therapeutic groups and providing supervision to emerging therapists. She is committed to ensuring every individual feels heard, valued and supported.</p>
                    <p>Jasmin takes an eclectic approach to therapy integrating cognitive behavioural therapy (CBT), acceptance and commitment therapy (ACT), Schema Therapy, and Dialectical Behaviour Therapy (DBT) skills. Currently, she is pursuing training in EMDR, further enriching her therapeutic toolkit.</p>
                    <p>Jasmin operates from a person-centered, trauma-informed perspective, valuing her clients' personal strengths and acknowledging they are the experts of their own experiences of mental illness. She is passionate about creating a safe and inclusive space for individuals from all walks of life, including members of the LGBTQIA+ community, and is committed to supporting all people in their journey towards mental wellbeing and growth.</p>
                    <p>Jasmin can see clients under Medicare, NDIS (self and plan managed), WorkCover, and private clients.</p>
                `
            },
            {
                img: JordanProfile,
                heading: "Jordan Self",
                title: "Provisional Psychologist",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Jordan is a Provisional Psychologist registered with AHPRA. She completed her Bachelor of Psychological Practice with a high distinction (Dux) and is currently pursuing her Master of Psychological Practice at Charles Sturt University. Last year, Jordan completed her research thesis on the links between social media, disordered eating behaviours, and body image.</p>

                    <p>Jordan has also volunteered for a national helpline, offering support to people across Australia dealing with feelings of loneliness. She has also co-facilitated Dialectical Behaviour Therapy (DBT) group therapy programs.</p>
                    
                    <p>Jordan believes in creating a warm and collaborative therapeutic relationship, where clients feel heard, supported, and empowered to make meaningful changes. With an empathetic and open-minded approach to therapy, Jordan primarily draws on Cognitive Behavioural Therapy (CBT) and DBT while continuously expanding her therapeutic toolkit. She works with a diverse range of clients and presentations, including anxiety, depression, stress, relationship challenges, and more.</p>
                    
                    <p>Jordan is welcoming and inclusive, providing a safe space for individuals of all backgrounds, gender identities, and sexual orientations.</p>
                    
                    <p>Jordan can see NDIS clients (self and plan managed) and private clients.</p>
                `
            },
            {
                img: juliaProfile,
                heading: "Julia Messore",
                title: "Provisional Psychologist",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Julia is a Provisional Psychologist registered with AHPRA. She has recently completed her Bachelor of Social Sciences (Sociology) and Bachelor of Psychological Science (Honours) graduating with distinction, and is currently completing her Master of Professional Psychology (School Psychology) at the University of Wollongong. Julia has experience working with young adults to effectively deliver mental health programs, and she has co-facilitated group therapy catered for individuals with borderline personality disorder (BPD) and trauma.</p>
                    <p>She also regularly volunteers with older Australians to reduce their loneliness and isolation through providing companionship.</p>
                    <p>Julia is interested in working with women’s mental health, the LGBTQIA+ community, depression, and anxiety. She can also conduct ADHD and autism assessments.</p>
                    <p>Julia can see clients privately or NDIS (self and plan managed).</p>
                `
            },
            {
                img: lauraProfile,
                heading: "Laura Corban",
                title: "Provisional Psychologist",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Laura is a Provisional Psychologist Registered with AHPRA. She completed her Bachelor of Psychology (Honours) at the University of Wollongong with a high distinction (Deans Merit) and is close to finishing her Master of Clinical Psychology at Macquarie University. As a result, she expects to obtain registration as a Registered Psychologist in May 2025. Laura's thesis research explored how mindset and environment influence psychological outcomes following a psychedelic experience. More recently, she has investigated the effect of different humour styles and coping strategies toward job satisfaction, burnout, and well-being among workers in emotionally demanding roles.</p>

                    <p>Laura has experience in a range of settings including, psychosocial disability support, community services, advocacy and social justice, mental health research, child protection, and public mental health. Laura has felt privileged to support people from all walks of life, including children, adolescents, adults, and older people from diverse cultural backgrounds, gender and sexual identities, physical abilities, and neurodivergence. Laura works with a range of clinical presentations, including but not limited to anxiety, depression, personality disorders, and trauma (developmental and complex), taking a particular interest in working with survivors of domestic violence and sexual assault.</p>
                    
                    <p>Laura utilises a person-centred, strengths-based approach to therapy, integrating techniques from Cognitive Behavioural Therapy (CBT), Acceptance and Commitment Therapy (ACT), Dialectical Behaviour Therapy (DBT), and Motivational Interviewing (MI) to address the unique needs of each individual. Laura has also conducted psychometric assessments for Autism and ADHD diagnoses, and will soon take part in training for Eye Movement Desensitization and Reprocessing (EMDR) Therapy.</p>
                    
                    <p>Laura holds great importance in creating a safe, supportive, and non-judgmental therapeutic space where individuals can share their stories, build self-awareness, practice self-compassion, feel empowered, and develop skills that foster growth and connection.</p>
                    
                    <p>Laura can see private clients and NDIS clients (self and plan managed).</p>
                `
            },
            {
                img: lukeProfile,
                heading: "Luke Fullagar",
                title: "Psychologist",
                pronouns: "He/Him",
                researchButton: false,
                description: `
                    <p>Luke is a registered psychologist with AHPRA who is devoted to helping clients walk a path
                    towards a sense of comfort, safety, meaning and wellbeing. Luke has worked with adults from
                    diverse backgrounds, cultures and identities and draws satisfaction from finding ways for people
                    to affirm who they are and cherish themselves for who they choose to be. He has worked with a
                    range of concerns including depression, anxiety, adjustment issues, PTSD, acute stress,
                    trauma, workplace issues as well as assessment and diagnosis. He has significant experience
                    in the worker’s compensation scheme and has an intimate knowledge of the process of, and
                    stakeholders to, a claim.</p>
                    <p>Luke takes an integrative approach to therapy drawing from a range of treatments including
                    Cognitive Behavioural Therapy (CBT), Acceptance and Commitment Therapy (ACT),
                    Mindfulness-Based Cognitive Therapy (MBCS), Mentalisation Therapy, Attachment Focussed
                    Therapy, Schema Therapy, Narrative Therapy and Dialectical Behaviour Therapy (DBT). Luke
                    is training in EMDR Therapy to assist people with PTSD and complex trauma.</p>
                    <p>Luke works with a client-focussed and trauma-informed focus that seeks to affirm the inherent
                    strengths and values of his clients and considers clients as the authority on their own life. He is
                    committed to psychological safety and works to maintain an inclusive environment for all
                    identities and cultures, including First Nations people, people who identify as having a disability
                    and the LGBTQIA+ community. He has experience with all of these populations and seeks to
                    affirm and validate the unique experiences that every client brings to their therapy.</p>
                    <p>Luke can see clients under Medicare, WorkCover, NDIS (plan and self managed), and private clients.
                    </p>
                `
            },
            {
                img: marcusProfile,
                heading: "Marcus Celebi",
                title: "Counsellor",
                pronouns: "He/They",
                researchButton: false,
                description: `
                    <p>Marcus is currently studying Masters of Counselling and Psychotherapy through the Australian College of Applied Professions and will gain membership with PACFA when completed. This is after completing a Bachelor of Psychological Science at the University of Wollongong.</p> 
                    <p>While practicing, Marcus uses his background in the mental health sphere and various volunteering and social roles to help people gain confidence, peace and learn to regulate their emotions. In their previous role with Hashworth Psychology's Diversity Clinic, Marcus worked as a therapy assistant and assisted in conducting neuro-affirming autism assessments.</p>

                    <p>Marcus is particularly interested in helping both children and adolescents. They have extensive therapy training in Acceptance & Commitment Therapy (ACT) and Cognitive Behavioral Therapy (CBT). Marcus also utilises a neuro-affirming approach and enjoys working with the LGBTQIA+ community, as well as other diverse groups.</p> 
                    
                    <p>Marcus can see NDIS (self and plan managed) and private clients.</p>
                `
            },
            {
                img: nicoleProfile,
                heading: "Nicole Herrald",
                title: "Counsellor",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Nicole completed her Bachelor of Psychological Sciences from Griffith University before undertaking a Diploma of Counselling. She is now studying a Master of Counselling through Torrens University and will gain membership with the Australian Counselling Association (ACA) upon completion. Nicole’s background in mental health has been an integral part of her work in the social work and counselling fields. She believes that giving young people the tools to cope with their emotions and stressors sets them on the best course for an emotionally fulfilling life.</p>
                    <p>Nicole favours a strengths-based approach to help clients realise their own capacity. She has extensive training in Cognitive Behavioural Therapy (CBT) and Dialectical Behaviour Therapy (DBT) and has a special interest in adolescent development.</p>
                    <p>Nicole enjoys working with the LGBTQIA+ community, trauma, personality disorders, mood and anxiety disorders, grief, and many more presentations.</p>
                    <p>Nicole can see NDIS clients (self and plan managed) and private clients.</p>
                `
            },
            {
                img: oliviaProfile,
                heading: "Olivia Green",
                title: "Clinical Psychologist",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Olivia is a Clinical Psychologist with AHPRA. She completed her Master of Clinical Psychology at The University of Sydney, as well as a Master of Research and Bachelor of Psychology (Honours) at Macquarie University. Olivia has extensive experience working in both public and NGO mental health settings, including community mental health, inpatient mental health units, headspace, and The Central Australian Aboriginal Congress in Alice Springs. Olivia is an authentic, open-minded, and compassionate therapist who draws upon numerous therapeutic approaches to provide individualised evidence-based treatment to clients. She recognises societal and systemic issues that contribute to mental ill health, working with individuals to feel safe and empowered to facilitate healing and growth. Areas of interest include personality disorders, trauma, mood and anxiety disorders (including depression, bipolar, OCD, and phobias), ecopsychology, grief and loss, and working with older adults. Olivia works with clients aged 16+ and welcomes working with members of the LGBTQIA+ community. She utilises a range of evidence-based therapeutic approaches, including:</p>
                    <ul>
                        <li>Cognitive Behavioural Therapy (CBT)</li>
                        <li>Dialectical Behavioural Therapy (DBT)</li>
                        <li>Exposure and Response Prevention</li>
                        <li>Acceptance and Commitment Therapy (ACT)</li>
                        <li>Eye Movement Desensitisation Reprocessing (EMDR) Therapy</li>
                    </ul>
                    <p>In her spare time, Olivia loves playing soccer, spending time with friends and family, and travelling.</p>
                    <p>Olivia can see clients privately, under Medicare, or NDIS (self and plan managed).</p>
                `
            },
            {
                img: rachelProfile,
                heading: "Rachel Mintoff",
                title: "Accredited Social Worker",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Rachel is an accredited Social Worker with the Australian Association of Social Workers (AASW) and a proud late-diagnosed AUDHDer (Autistic + ADHD). Rachel holds a Bachelor of Social Work with Distinction from the University of Wollongong and is currently completing further studies to develop and teach health practitioners and clients about neuroaffirming and disability-inclusive practices.</p>

                    <p>Rachel's practice is deeply rooted in her own lived experience and career as a Social Worker. She is passionate about providing neuroaffirming, trauma-informed, and inclusive support that empowers her clients. Additionally, she is passionate about supporting the LGBTQIA+ community and prioritises inclusivity. Rachel draws on a variety of therapeutic approaches, including Acceptance and Commitment Therapy (ACT), Narrative Therapy, Person-Centered Therapy, Coaching, and Strengths-Based methods, all tailored to meet her clients' individual needs and goals. She will also be undergoing training in Eye Movement Desensitization and Reprocessing (EMDR) Therapy.</p>
                    
                    <p>With a strong belief that clients are the experts in their own lives, Rachel works alongside them, offering both therapeutic and practical support. Her approach is client-focused, goal-oriented, and always sensitive to the unique challenges faced by each individual.</p>
                    
                    <p>Driven by her passion to support neurodivergent individuals, Rachel spends her spare time researching and trialing strategies and methods to enhance her practice. She brings this dedication into her work, helping clients navigate their journeys in a way that feels true to them.</p> 
                    
                    <p>Rachel's personal and professional experiences are at the heart of her practice, making her a compassionate and effective ally in her clients' journeys toward healing and empowerment.</p>
                    
                    <p>Rachel can see clients privately or NDIS (self and plan managed).</p>
                `
            },
            {
                img: salaProfile,
                heading: "Sala Ghanem",
                title: "Counsellor",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Sala currently holds a Bachelor of Psychology (Honours) and aims to pursue a Masters of Clinical Psychology. Her educational background and previous roles in youth mentoring and co-facilitating roles reflect a strong foundation in the field. She is particularly drawn to employing a client-centred holistic approach when working with individuals.</p>
                    <p>In her approach to counselling, Sala places a special emphasis on three key therapeutic modalities: Cognitive Behavioural Therapy (CBT), Mindfulness-Based Therapy (MBT), and Dialectical Behavioural Therapy (DBT). These methods highlight her commitment to connect with and empower individuals. Particularly, Sala’s passion lies in creating an inclusive and supportive space for everyone, ensuring equal opportunities for each person to unlock their fullest potential. Sala is bilingual in English and Arabic and is passionate about supporting individuals of diverse backgrounds. </p>
                    <p>Sala’s expertise extends to group settings, where she has been involved in co-facilitating various DBT group therapy programs. Sala is interested in working with individuals with borderline personality disorder (BPD), trauma, the LGBTQIA+ community, depression, anxiety, bipolar disorder, and many more presentations. </p>
                    <p>Sala can see NDIS clients (self and plan managed) and private clients.</p>
                `
            },
            {
                img: saraProfile,
                heading: "Sara Oldfield",
                title: "Psychologist and Clinical Psychology Registrar",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Sara is a Registered Psychologist and Clinical Psychology Registrar accredited with AHPRA. She earned her Bachelor of Psychology and Graduate Diploma of Professional Psychology from the University of Wollongong in 2010 and 2012, respectively. Driven by a passion for learning, she completed a Master of Forensic Mental Health at the University of New South Wales in 2014. During the COVID-19 lockdowns, Sara pursued further education, completing her Master of Clinical Psychology at the University of New England in 2023.</p>
                    <p>Sara is also a board approved supervisor and provides supervision to early career psychologists.</p>
                    <p>With over 15 years of experience working with children, adolescents, and adults, Sara specialises in areas such as anxiety, trauma, neurodiversity, giftedness, and perfectionism. She approaches therapy as a collaborative process, prioritising understanding her clients' perspectives and working alongside them to achieve meaningful, personalised goals.</p>
                    <p>Sara recognises that seeking psychological support can feel overwhelming and is committed to creating a comfortable, supportive environment. She values open communication and actively seeks feedback to ensure her approach aligns with individual needs.</p>
                    <p>Sara works with clients from diverse backgrounds and neurotypes, operating within a client-centered, recovery-oriented framework that promotes empowerment, capacity building, and hope. She integrates evidence-based interventions into her practice, including EMDR Therapy, Cognitive Behavioural Therapy, Acceptance and Commitment Therapy, Dialectical Behaviour Therapy, and psychoeducation. Sara enjoys sharing insights into neuropsychology and brain-based science when relevant.</p>
                    <p>In addition to therapy, Sara dedicates time to diagnostic assessments. She is skilled in cognitive assessments (IQ, intellectual disabilities, learning disorders) and neuro-affirming assessments for conditions such as Autism, ADHD, and OCD.</p>
                    <p>Sara provides services under Medicare, NDIS (plan and self-managed), and WorkCover frameworks.</p>
                    <p>Outside of work, Sara enjoys baking, going for adventures, and spending time with her family. Her most memorable experiences include hiking with silverback gorillas in Rwanda, and becoming a mum.</p>
                `
            }
        ]
    },
    behindTheScenesStaff: {
        header: "Behind the Scenes",
        staff: [
            {
                img: alanaProfile,
                heading: "Alana Joseph",
                title: "Practice Manager",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Alana is in charge of all of the behind–the-scenes action here at Hashworth Psychology's Diversity Clinic. She is trained as a primary school teacher and has always been interested in mental health, as she has extensive experience working with additional needs and in embedding trauma informed practice into her work. She is very personable and loves working with people, so be sure to say hi and ask her any questions when you see her at the office. When she is not at work, you’ll find Alana spending every spare minute with her partner and dogs, or lifting heavy things in the gym!</p>
                `
            },
            {
                img: athenaProfile,
                heading: "Athena Staughton",
                title: "Therapy Assistant",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Athena is a student at UOW currently studying a Bachelor of Psychology (Honours), and intends on pursuing a Clinical Masters degree and a career in mental health. When she is not studying, Athena does psychosocial support work, and has a passion for addressing the disparity in access to important psychological services. This is the area Athena hopes to use her degree in the future, and has a specific interest in working with those affected by trauma. To do this, Athena is learning important skills from the experienced team at Hashworth Psychology while she works towards finishing her degree. When not working or studying, Athena enjoys going for hikes, cooking with her friends and visiting her family in Canberra.</p>
                `
            },
            {
                img: caitlinProfile,
                heading: "CJ Willer",
                title: "Administration",
                pronouns: "They/Them",
                researchButton: false,
                description: `
                    <p>CJ brings a strong passion for and interest in mental health and LGBTQIA+ inclusion to their role at Hashworth Psychology's Diversity Clinic. This is supported by their experience in customer service and skills in organisation. Outside of work, CJ enjoys reading, dancing, and puzzles.</p>
                `
            },
            {
                img: darcyProfile,
                heading: "Darcy Smith",
                title: "Therapy Assistant",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Darcy has completed her Bachelor of Psychology (Honours) with distinction and endeavours
                    to pursue a career as a clinical psychologist in the future. Darcy is interested in working in
                    complex mental health, and with children, adolescents and young adults. Her core values
                    include kindness, compassion and empathy and she aims to adhere to these in all aspects of
                    her work. In her spare time, Darcy loves listening to music, reading, spending time with her
                    pets, and time at the beach.</p>
                `
            },
            {
                img: elizaProfile,
                heading: "Eliza Brewin",
                title: "Therapy Assistant",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Eliza has completed her Bachelor of Psychology (Honours) and is passionate about pursuing a career as a clinical psychologist in the future. She is interested in working with adolescents, young adults and new parents as they navigate new unfolding chapters in their lives. In her spare time she is always down for a dip in the ocean, catching up with friends or doing CrossFit.</p>
                `
            },
            {
                img: KatProfile,
                heading: "Katrina Beretov",
                title: "Administration",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Kat is currently seeking a Bachelor of Laws & Bachelor of Arts (Psychology) at the University of Wollongong, where she has had the opportunity to marry two interests of hers; understanding why humans do what they do, and the legal system that regulates social movement. She intends to utilise her studies to help marginalised groups in navigating complex welfare systems through advocacy and policy change. She has a keen interest in pursuing a career as a mental health practitioner, helping those who identify as Culturally and Linguistically Diverse (CALD), senior citizens, LGBTQIA+, facing socio-economic adversity and individuals identifying with a disability.
                    With almost a decade in customer service roles, as well as thorough administrative/front desk experience, Kat is patient and always eager to greet you with a smile and a giggle. Kat has native fluency in the English language and proficient in spoken Macedonian.</p>
                    <p>When Kat is not in the clinic, or attending classes, you can find her in her Baba's kitchen making traditional Macedonian sweets, at pilates or in the gym, grabbing a cheeky sweet treat with her friends, or singing her heart out with her Dedo to TurboFolk.</p>
                `
            },
            {
                img: rozhinaProfile,
                heading: "Rozhina Ducklin",
                title: "Therapy Assistant",
                pronouns: "She/Her",
                researchButton: false,
                description: `
                    <p>Rozhina is in the final year of her Bachelor of Psychological Science at the University of Wollongong, with the intention of pursuing a Master’s in Clinical Psychology. She has a strong interest in youth mental health and early intervention, aiming to create supportive environments where young people feel heard and empowered. Through her studies and experience at Hashworth Psychology's Diversity Clinic, she hopes to make a meaningful impact in this field.</p>

                    <p>Alongside her studies, Rozhina volunteers at the Starlight Express Room at the children's hospital, works with Headspace to improve youth mental health, and teaches gymnastics to young children to encourage movement and confidence. Through these experiences, she continues to develop valuable skills that will inform her future work in psychology. When she is not working or studying, Rozhina enjoys attending workout classes, reading, and spending time with friends.</p>
                `
            },
        ]
    }
}